import React from 'react'
import GetRequest from '../Services/APIRequest'
import { useState } from "react";


const CostCentreWiseBalanceForDistributor = () => {

  const distID = localStorage.getItem('distributorId');

  const { data } = GetRequest(`/Account/GetCostCentreBalancesForBoosterAndParis/${distID}`);

  const formatCurrency = (amount) =>
    new Intl.NumberFormat('en-IN', {
      // style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);


  return (
    <div className='row'>
      {
        data.result && data.result.boosterCostCentres && data.result.boosterCostCentres.map((item, index) => (
          <div className="col-lg-3 col-4">
            <div className={`small-box bg-success`}>
              <div className="inner">
                <h4>  {item.name}    <sub ></sub></h4>
                <p>
                  {formatCurrency(item.totalBalance)}     </p>              </div>
            </div>
          </div>
        ))
      }

      {
        data.result && data.result.parisCostCentres && data.result.parisCostCentres.map((item, index) => (
          <div className="col-lg-3 col-4">
            <div className={`small-box bg-info`}>
              <div className="inner">
                <h4>  {item.name}    <sub ></sub></h4>
                <p>
                  {formatCurrency(item.totalBalance)}      </p>
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}


export default CostCentreWiseBalanceForDistributor