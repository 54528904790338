import React from 'react'
import GetRequest from '../Services/APIRequest'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { useState } from "react";


const IndicatorsForSalesTeamMember = ({ filterData }) => {
  const navigate = useNavigate();
  // const [filterDataState, setFilterData] = useState(filterData); // Add state for filterData

  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');
  const bpuID = localStorage.getItem('userId');
  localStorage.setItem('orderSearchStatus', "All");
  localStorage.setItem('receiptSearchStatus', "All");

  const { data } = GetRequest(`/Orders/GetStatusWiseCountForBPUser/${parseInt(bpuID)}/${filterData}/fromDt/toDate`);

  const NavigateToOrders = (orderSearchStatus) => {
    localStorage.setItem('orderSearchStatus', orderSearchStatus);
    navigate("/BPUDashboard/OrderList/-1");
  };

  const NavigateToReceipts = (receiptSearchStatus) => {
    localStorage.setItem('receiptSearchStatus', receiptSearchStatus);
    navigate("/BPUDashboard/PaymentList/-1");
  };


  return (
    <div>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header topnav">
              <h3 className="card-title">Pending For Staff Approval</h3>
            </div>
            <div className="row card-body">
              <div className="col-lg-6 col-6">
                <div className={`small-box bg-approved`} onClick={() => { NavigateToOrders(`PendingForReview`); }}>
                  <div className="inner">
                    <h3>   {data?.result?.pendingOrdersCountForSelectedDuration !== undefined ? (
                      <span>{data.result.pendingOrdersCountForSelectedDuration}/{data.result.totalPendingOrdersCount}</span>
                    ) : (
                      <span>No data available</span> // Fallback in case data is undefined
                    )} <sub ></sub></h3>
                    <p className="smallfontindicator">Orders</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-arrow-right arrowDark"></i>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-6">
                <div className={`small-box bg-processing`} onClick={() => { NavigateToReceipts(`PendingForReview`); }}>
                  <div className="inner">

                    <h3>    {data?.result?.pendingReceiptsCountForSelectedDuration !== undefined ? (
                      <span>{data.result.pendingReceiptsCountForSelectedDuration}/
                        {data.result.totalPendingReceiptsCount}
                      </span>
                    ) : (
                      <span>No data available</span> // Fallback in case data is undefined
                    )} <sub ></sub></h3>
                    <p className="smallfontindicator">Receipts</p>
                  </div>
                  <div className="icon">
                    <i className="fa fa-arrow-right arrowLittleDark"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-primary">
            <div className="card-header topnav">
              <h3 className="card-title">Orders Status</h3>
            </div>
            <div className="row card-body">
              <div className="row">
                {
                  data.result &&
                  data.result.orders
                    .filter((item) => !item.name.toLowerCase().includes("pending")) // Exclude items with "pending" in the name
                    .map((item, index) => (
                      <div className="col-lg-3 col-6" key={index}>
                        <div
                          className={`small-box ${item.style}`}
                          onClick={() => {
                            NavigateToOrders(`${item.name}`);
                          }}
                        >
                          <div className="inner">
                            <h4>
                              {item.displayName} <sub></sub>
                            </h4>
                            <p>{item.value}</p>
                          </div>
                          <div className="icon">   <i className="fa fa-arrow-right"></i></div>
                        </div>
                      </div>
                    ))
                }
              </div >
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="card card-success">
            <div className="card-header topnav">
              <h3 className="card-title">Receipts Status</h3>
            </div>
            <div className="row card-body">
              <div className="row">
                {
                  data.result && data.result.receipts
                    .filter((item) => !item.name.toLowerCase().includes("pending")).map((item, index) => (
                      <div className="col-lg-4 col-4">
                        <div className={`small-box ${item.style}`} onClick={() => { NavigateToReceipts(`${item.name}`); }} >
                          <div className="inner">
                            <h4>{item.displayName} <sub ></sub></h4>
                            <p>
                              {item.value}      </p>
                          </div>
                          <div className="icon">
                            <div className="icon">   <i className="fa fa-arrow-right"></i></div>
                          </div>
                        </div>
                      </div>
                    ))
                }
              </div >
            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default IndicatorsForSalesTeamMember