import React from 'react'
import axios from 'axios';
import { useState } from 'react';
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import PaymentsAgainOutstandingsEdit from './PaymentsAgainOutstandingsEdit';

const PaymentDistributionsEdit = () => {
  const navigate = useNavigate();
  const distID = localStorage.getItem('distributorId');
  const boostID = localStorage.getItem('companyIdBooster');
  const parisID = localStorage.getItem('companyIdParis');

  const { data, error, isLoaded } = GetRequest(`/Scheme/GetRunningSchemesForCompany/${distID}/${boostID}`);


  if (error !== null) {
    return <Error data="All Running Schemes For Company & Payment Against Outstandings" />;
  }
  if (!isLoaded) {
    return <Loading />;
  }
  const notify = () => toast.error('Your Total value paid less than entered value !', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  });
  const notifySuccess = () => toast.success('Please enter OTP recived on your Mobile !', {
    position: "bottom-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored"
  });



  const formData = {};
  formData.schemeDistributionDetails = [];
  formData.invoiceDistributionDetails = [];
  formData.costCentreWisePaymentDistribution = [];
  const handleChangePAD = (event) => {
    event.preventDefault();
    // const curId = event.currentTarget.id;
    // if (formData.costCentreWisePaymentDistribution.length !== 0) {
    //   if (formData.costCentreWisePaymentDistribution.some(itk => itk.costCentreId === curId)) {
    //     formData.costCentreWisePaymentDistribution.forEach((itchk, index) => {
    //       if (itchk.costCentreId === curId) {
    //         formData.costCentreWisePaymentDistribution[index].costCentreId = event.currentTarget.id;
    //         formData.costCentreWisePaymentDistribution[index].paymentId = document.getElementById("paymentIdResData").value
    //         formData.costCentreWisePaymentDistribution[index].paidAmountAgainstCostCentre = event.target.value;
    //       }
    //     });

    //   } else {
    //     const sDTODateItem = {
    //       costCentreId: event.currentTarget.id,
    //       paymentId: document.getElementById("paymentIdResData").value,
    //       paidAmountAgainstCostCentre: event.target.value,
    //       comments: "",
    //     }
    //     formData.costCentreWisePaymentDistribution.push(sDTODateItem);
    //     // minusTotalAmoutPaid(event);

    //   }
    // } else {
    //   const sDTODateItem = {
    //     costCentreId: event.currentTarget.id,
    //     paymentId: document.getElementById("paymentIdResData").value,
    //     paidAmountAgainstCostCentre: event.target.value,
    //     comments: "",
    //   }
    //   formData.costCentreWisePaymentDistribution.push(sDTODateItem);
    //   // minusTotalAmoutPaid(event);

    // }

  }


  const handleChangeSD = (event) => {
    event.preventDefault();
    const curId = event.currentTarget.id;
    if (formData.schemeDistributionDetails.length !== 0) {
      if (formData.schemeDistributionDetails.some(itk => itk.schemeDateWiseDetailsId === curId)) {
        formData.schemeDistributionDetails.forEach((itchk, index) => {
          if (itchk.schemeDateWiseDetailsId === curId) {
            formData.schemeDistributionDetails[index].schemeDateWiseDetailsId = event.currentTarget.id;
            formData.schemeDistributionDetails[index].paymentId = document.getElementById("paymentIdResData").value
            formData.schemeDistributionDetails[index].paidAmountForScheme = event.target.value;
          }
        });

      } else {
        const schemesDetailsData = {
          paymentId: document.getElementById("paymentIdResData").value,
          schemeId: event.target.getAttribute('data-scheme-id'),
          schemeDateWiseDetailsId: event.target.id,
          paidAmountForScheme: event.target.value,
          comments: "",
          schemName: ""
        }
        formData.schemeDistributionDetails.push(schemesDetailsData);

      }
    } else {
      const schemesDetailsData = {
        paymentId: document.getElementById("paymentIdResData").value,
        schemeId: event.target.getAttribute('data-scheme-id'),
        schemeDateWiseDetailsId: event.target.id,
        paidAmountForScheme: event.target.value,
        comments: "",
        schemName: ""
      }
      formData.schemeDistributionDetails.push(schemesDetailsData);

    }

  }


  function loaderToggle() {
    document.getElementById('loaderFormSubmit').classList.toggle('hide');
  }

  return (
    <>
      <div id="accordion3">
        <h5 className='payDistHead' data-bs-toggle="collapse" href={`#collapseSchemesSec`} >Payment Distribution Across Schemes
          <a className="btn dp-right-mt5 accUIIcon">
            <i className="fa fa-angle-double-down"></i>
            <i className="fa fa-angle-double-up hide"></i>
          </a>
        </h5>
        <div id={`collapseSchemesSec`} className="collapse" data-bs-parent="#accordion3">

          <div id="accordion">
            {
              data.result && data.result.map((item, index) => (
                <div className="card paydistheadCardnopad ">
                  <div className="card-header hdcolacc text-white" data-bs-toggle="collapse" href={`#collapse${item.id}`}>
                    <a className="btn" >
                      {item.name} - {item.schemeType}

                    </a>
                    <a className="btn dp-right-mt5 accUIIcon">
                      <i className="fa fa-angle-double-down"></i>
                      <i className="fa fa-angle-double-up hide"></i>
                    </a>
                  </div>
                  <div id={`collapse${item.id}`} className="collapse" data-bs-parent="#accordion">
                    <div className="card-body">
                      {
                        item.schemeDTODateDetailsItems && item.schemeDTODateDetailsItems.map((itemInner, index1) => (
                          <div className='row sepSecLine'>
                            <div className='col-sm-6'> <b> {itemInner.fromDateString}</b> To <b>{itemInner.toDateString}</b>
                              - Discount : <b>{itemInner.discountAmount} % </b><br />
                              <b>Discount Type : {itemInner.discountType}</b> <br />
                              <b>Details :</b> {itemInner.details}<br />
                            </div>

                            <div className='col-sm-6 mobpadpadpad'>
                              {itemInner.isActive === false ? <label className='text-danger'>Offer Period Expired </label> :
                                <input type='number' id={itemInner.id} data-scheme-id={item.id} className='form-control payOutTextScheme' name='paidAmountForScheme' onBlur={handleChangeSD} />}
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <PaymentsAgainOutstandingsEdit funcname={handleChangePAD} />

      {/* <OutstandingInvoices funcname={handleChangeIDD} />  */}



      <div id='loaderFormSubmit' className='col-md-12 hide'>
        <div id="loaderScreen" ></div>
      </div>

      <ToastContainer />

    </>
  )

}

export default PaymentDistributionsEdit