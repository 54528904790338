import React from 'react'
import SchemesSlider from '../Schemes/SchemesSlider'
import EventsHome from './EventsHome';
import MustOrderHome from './MustOrderHome';
import InventoryHome from './InventoryHome';
import FarmerSuggetions from './FarmerSuggetions';
import HomeTopButtonGroup from '../Components/HomeTopButtonGroup';
import CreditBalanceBoosterBox from '../IndicatorBox/CreditBalanceBoosterBox';
import CreditBalanceParisBox from '../IndicatorBox/CreditBalanceParisBox';
import CostCentreWiseBalanceForDistributor from '../IndicatorBox/CostCentreWiseBalanceForDistributor';
import FarmerInAreaBox from '../IndicatorBox/FarmerInAreaBox';
import FarmerAddedLastWeekBox from '../IndicatorBox/FarmerAddedLastWeekBox';

const Home = () => {

  setTimeout(function () {
    const checkUserType = localStorage.getItem('userType');
    if (checkUserType == 'BPUser') {
      window.location.replace("/BPUDashboard/BPUDashContent");
    }
  }, 100);

  return (
    <>
      <SchemesSlider />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="row card-body">
              {/* <HomeTopButtonGroup /> */}
              <CreditBalanceBoosterBox />
              <CreditBalanceParisBox />
              {/* <FarmerInAreaBox /> */}
              <CostCentreWiseBalanceForDistributor />
              {/* <FarmerAddedLastWeekBox /> */}
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        {/* <InventoryHome /> */}
        <FarmerSuggetions />
        <MustOrderHome />
        <EventsHome />
      </div>

    </>
  )
}

export default Home