import React from 'react'
import GetRequest from '../Services/APIRequest'
import Loading from '../Components/Loading'
import Error from '../Components/Error'
import { Link } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import IndicatorsForSalesTeamMember from '../IndicatorBox/IndicatorsForSalesTeamMember'
import AccountsSummaryTotalAndCostCentreWise from '../IndicatorBox/AccountsSummaryTotalAndCostCentreWise'
import { useState } from "react";

const BPUDashContent = () => {
    const navigate = useNavigate();
    const [filterData, setFilterData] = useState('ThisWeek'); // Add state for filterData

    const distID = localStorage.getItem('distributorId');
    const boostID = localStorage.getItem('companyIdBooster');
    const parisID = localStorage.getItem('companyIdParis');
    const bpuID = localStorage.getItem('userId');
    localStorage.setItem('receiptSearchStatus', '');

    const HandleNewOrderClick = (selectedDistributorId) => {
        localStorage.setItem('distributorId', selectedDistributorId);
        navigate("/NewOrder");
    };


    const HandleNewPaymentClick = (selectedDistributorId) => {
        localStorage.setItem('distributorId', selectedDistributorId);
        navigate("/NewPayment/SubmitPayment");
    };

    const HandleDistributorReportsClicked = (selectedDistributorId) => {
        localStorage.setItem('distributorId', selectedDistributorId);
        navigate("/BPUDashboard/DistributorReports");
    };


    const HandleAccountSummaryClick = (selectedDistributorId) => {
        localStorage.setItem('distributorId', selectedDistributorId);
        navigate("/AccountSummary");
    };

    const HandleFilterClick = (newFilterData) => {
        setFilterData(newFilterData); // Update state with new filter data
        const buttons = document.querySelectorAll('.listfilterhead');
        buttons.forEach(button => {
            if (button.getAttribute('data-filter') === newFilterData) {
                button.classList.add('active'); // Add 'active' class to the matching button
            } else {
                button.classList.remove('active'); // Remove 'active' class from non-matching buttons
            }
        });
    };

    const { data, error, isLoaded } = GetRequest(`/User/GetDistributorsAssignedToUser/${bpuID}`);

    if (error !== null) {
        return <Error data="My Distributor" />;
    }
    if (!isLoaded) {
        return <Loading />;
    }

    const formatCurrency = (amount) =>
        new Intl.NumberFormat('en-IN', {
            // style: 'currency',
            currency: 'INR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);

    function textFilterProducts() {
        // alert('test1');
        try {
            var input, filter, ul, li, a, i, txtValue;
            input = document.getElementById("searchProductFilter");
            //alert('test2');
            filter = input.value.toUpperCase();
            ul = document.getElementById("accordionExample");

            li = ul.getElementsByTagName("h2");
            for (i = 0; i < li.length; i++) {
                a = li[i];
                txtValue = a.textContent || a.innerText;
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].parentElement.style.display = "";
                } else {
                    li[i].parentElement.style.display = "none";
                }
            }
        }
        catch (e) {

        }
    }

    return (
        <>
            <section className="content">
                <div className="container-fluid">
                    {/* <div className="row">
                        <div className="col-sm-12">
                            <div className="card noUI productListNewOrd">
                                <div className="card-header headerNewOrderFlt">
                                    <button className="form-control flBtn listfilterhead" data-filter="Today" onClick={() => { HandleFilterClick(`Today`); }}> Today </button>
                                    <button className="form-control flBtn listfilterhead active" data-filter="ThisWeek" onClick={() => { HandleFilterClick(`ThisWeek`); }}> This Week </button>
                                    <button className="form-control flBtn listfilterhead" data-filter="LastWeek" onClick={() => { HandleFilterClick(`LastWeek`); }}> Last Week </button>
                                    <button className="form-control flBtn listfilterhead" data-filter="CurrentMonth" onClick={() => { HandleFilterClick(`CurrentMonth`); }}> This Month </button>
                                    <button className="form-control flBtn listfilterhead" data-filter="LastMonth" onClick={() => { HandleFilterClick(`LastMonth`); }}> Last Month </button>
                                    <button className="form-control flBtn listfilterhead" data-filter="CurrentYear" onClick={() => { HandleFilterClick(`CurrentYear`); }}> Current Year </button>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <IndicatorsForSalesTeamMember filterData={filterData} />
                    {<AccountsSummaryTotalAndCostCentreWise filterData={filterData} />}

                    <div className="row">
                        <div className="col-12">
                            <div className="card card-primary">
                                <div className="card-header topnav">
                                    <h3 className="card-title">My Parties</h3>
                                    <div className="search-container">
                                        <input type="text" id="searchProductFilter" onKeyUp={textFilterProducts} placeholder="Search by Distributor Name .." name="search" />
                                        <button type="submit"><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div class="accordion" id="accordionExample">
                                        {
                                            data.result && data.result.map((item, index) => (
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header">
                                                        <button class="accordion-button collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target={`#${item.id}`}
                                                            aria-expanded="false" aria-controls={`${item.id}`}>
                                                            {item.distributorDisplayName}
                                                            {item.distributorForOrgs === "Both" && " [B+P]"}
                                                            {item.distributorForOrgs === "Booster" && " [B]"}
                                                            {item.distributorForOrgs === "Paris" && " [P]"}
                                                        </button>
                                                    </h2>
                                                    <div id={`${item.id}`} class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                                        <div class="accordion-body">
                                                            <div class="row">
                                                                <div className='btnGroupFoot'>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-6 mb-2">
                                                                        <Link to={`/BPUDashboard/PaymentList/${item.id}`} className='btn btn-success btn-sm'>  Payments   </Link> &nbsp;
                                                                    </div>
                                                                    <div class="col-6 mb-2">
                                                                        <Link to={`/BPUDashboard/OrderList/${item.id}`} className='btn btn-primary btn-sm'>  Orders    </Link>  &nbsp;
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-6 mb-2">
                                                                        <button className='btn btn-success btn-sm' onClick={() => { HandleNewOrderClick(`${item.id}`); }}> New Order </button> &nbsp;
                                                                    </div>
                                                                    <div class="col-6 mb-2">
                                                                        <button className='btn btn-success btn-sm' onClick={() => { HandleNewPaymentClick(`${item.id}`); }}> New Payment </button> &nbsp;
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-6 mb-2">
                                                                        <button className='btn btn-success btn-sm' onClick={() => { HandleDistributorReportsClicked(`${item.id}`); }}> Reports </button> &nbsp;
                                                                    </div>
                                                                    <div class="col-6 mb-2">
                                                                        <button className='btn btn-success btn-sm' onClick={() => { HandleAccountSummaryClick(`${item.id}`); }}> A/C Summary </button> &nbsp;
                                                                    </div>
                                                                </div>

                                                                {item.costCentreDetailsBoosterAndParis &&
                                                                    item.costCentreDetailsBoosterAndParis.boosterCostCentres &&
                                                                    item.costCentreDetailsBoosterAndParis.boosterCostCentres.filter(
                                                                        (costCentreDetails) => costCentreDetails.totalBalance !== 0
                                                                    ).length > 0 && (
                                                                        <div class="row">
                                                                            <div className="card card-primary">
                                                                                <div className="card-header topnav">
                                                                                    <h5 className="card-title">Booster</h5>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <table className="table table-bordered">
                                                                                        {item.costCentreDetailsBoosterAndParis &&
                                                                                            item.costCentreDetailsBoosterAndParis.boosterCostCentres &&
                                                                                            item.costCentreDetailsBoosterAndParis.boosterCostCentres.map((costCentreDetails, index) =>
                                                                                                costCentreDetails.totalBalance !== 0 ? (
                                                                                                    <tr key={index}>
                                                                                                        <td>{costCentreDetails.name}</td>
                                                                                                        <td>{formatCurrency(costCentreDetails.totalBalance)}</td>
                                                                                                    </tr>
                                                                                                ) : null
                                                                                            )}
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                {item.costCentreDetailsBoosterAndParis &&
                                                                    item.costCentreDetailsBoosterAndParis.parisCostCentres &&
                                                                    item.costCentreDetailsBoosterAndParis.parisCostCentres.filter(
                                                                        (costCentreDetails) => costCentreDetails.totalBalance !== 0
                                                                    ).length > 0 && (
                                                                        <div class="row">
                                                                            <div className="card card-primary">
                                                                                <div className="card-header topnav">
                                                                                    <h5 className="card-title">Paris</h5>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <table className='table table-bordered'>
                                                                                        {item.costCentreDetailsBoosterAndParis &&
                                                                                            item.costCentreDetailsBoosterAndParis.parisCostCentres &&
                                                                                            item.costCentreDetailsBoosterAndParis.parisCostCentres.map((costCentreDetails, index) =>
                                                                                                costCentreDetails.totalBalance !== 0 ? (
                                                                                                    <tr key={index}>
                                                                                                        <td>{costCentreDetails.name}</td>
                                                                                                        <td>{formatCurrency(costCentreDetails.totalBalance)}</td>
                                                                                                    </tr>
                                                                                                ) : null
                                                                                            )}
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                <div className="row">
                                                                    <div className="col-lg-3 col-6">
                                                                        <div className="small-box scol2">
                                                                            <div className="inner">
                                                                                <h3 className='indicatorBoxH3'>{formatCurrency(item.boosterCreditLimit)}<sub ></sub></h3>
                                                                                <p className="smallfontindicator">Booster Cr Limit</p>
                                                                            </div>
                                                                            <div className="icon">
                                                                                <i className="fa fa-inr"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-3 col-6">
                                                                        <div className="small-box bg-info">
                                                                            <div className="inner">
                                                                                <h3 className='indicatorBoxH3'>{formatCurrency(item.parisCreditLimit)} <sub ></sub></h3>
                                                                                <p className="smallfontindicator">Paris Cr Limit</p>
                                                                            </div>
                                                                            <div className="icon">
                                                                                <i className="fa fa-inr"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-6">
                    <div className="card card-primary">
                        <div className="card-header">
                            <h3 className="card-title"> Payment List  </h3>
                        </div>

                        <div className="card-body">
                            <div className="row">
                                
                            </div>
                        </div>
                    </div>
                </div> */}
                    </div>

                </div>
            </section>
        </>
    )
}

export default BPUDashContent